import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import * as tf from '@tensorflow/tfjs';


const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        maxWidth: 400,
        padding: theme.spacing.unit * 1,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '90%',

    },

    titletext: {
        textAlign: 'center',
    },

    divider: {

        width: '90%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '90%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 200,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});


let tumorsize;
let tumornumber;
let ggt;
let pt;
let vi;

let model;

class RScoreANN extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TumorSize: '',
            TumorNumber: '',
            GGT: '',
            PT: '',
            VI: 1,
            RScore: '',
            OS: '',
            RFS: '',
            RScoreANN: '',
        };
        tumorsize = 0;
        tumornumber = 0;
        ggt = 0;
        pt = 0;
        vi = 1;

    }


    componentDidMount() {

        (async () => {
            //model = await tf.loadLayersModel('./tfjs/model.json');
            //model = await tf.loadLayersModel('https://d.hydjan.com/tfjs/rscore/05/model.json');
            model = await tf.loadLayersModel('https://d.hydjan.com/tfjs/rscore/05/model.json');
            // const handler = tfnode.io.fileSystem('tfjs/model.json');
            // model = await tf.loadLayersModel(handler);
            console.log("Model loaded")


        })();

    }


    handleChange = name => event => {

        var v = event.target.value
        if (v === '') {

            if (name === "TumorSize") {
                tumorsize = 0
            } else if (name === "TumorNumber") {
                tumornumber = 0
            } else if (name === "GGT") {
                ggt = 0
            } else if (name === "PT") {
                pt = 0
            }

            this.setState({
                [name]: event.target.value,
                RScore: '',
                OS: '',
                RFS: '',
                RScoreANN: '',

            });

        } else if (!isNaN(v)) {


            if (name === "TumorSize") {
                tumorsize = parseFloat(v)
            } else if (name === "TumorNumber") {
                tumornumber = parseFloat(v)
            } else if (name === "GGT") {
                ggt = parseFloat(v)
            } else if (name === "PT") {
                pt = parseFloat(v)
            } else if (name === "VI") {
                vi = v
            }

            if (tumorsize !== 0 && tumornumber !== 0 && ggt !== 0 && pt !== 0) {

                var rscore = 0.58 * Math.log(tumorsize) + 0.439 * Math.log(tumornumber) + 0.251 * Math.log(ggt) + 1.928 * Math.log(pt) + 0.467 * (vi - 1);
                var os = 337186 * Math.pow(2.718, -1.253 * rscore);
                var rfs = 334748 * Math.pow(2.718, -1.339 * rscore)
                const tumornumber1 = (tumornumber - 1.0) / 8.0;
                const tumorsize1 = (tumorsize - 0.5) / 29.5;
                const ggt1 = (ggt - 0.0) / 1311.0;
                const pt1 = (pt - 8.0) / 32.0;
                const vi1 = (vi - 0.0) / 2.0;


                if (model) {
                    //const example = tf.tensor2d([[0.6, 0.6, 0.6, 0.6, 0.6]]);

                    const example = tf.tensor2d([[tumornumber1, tumorsize1, ggt1, pt1, vi1]]);

                    const prediction = model.predict(example);
                    prediction.print()
                    prediction.data().then(
                        (x) => {
                            console.log(x);
                            this.setState({
                                RScoreANN: (x[1] * 100).toFixed(2) + "%",
                            });
                        }
                    );
                }


                this.setState({
                    RScore: rscore.toFixed(2),
                    OS: os.toFixed(1) + " months",
                    RFS: rfs.toFixed(1) + " months",


                })

            }



            this.setState({ [name]: event.target.value });



        }




    };

    render() {
        const { classes } = this.props;



        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>RScore</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />

                    <TextField
                        id="ts"
                        label="Tumor Size (cm)"
                        className={classes.textField}
                        value={this.state.TumorSize}
                        onChange={this.handleChange('TumorSize')}
                        margin="normal"
                    />

                    <TextField
                        id="tn"
                        label="Tumor Number"
                        className={classes.textField}
                        value={this.state.TumorNumber}
                        onChange={this.handleChange('TumorNumber')}
                        margin="normal"
                    />
                    <TextField
                        id="ggt"
                        label="GGT (U/L)"
                        className={classes.textField}
                        value={this.state.GGT}
                        onChange={this.handleChange('GGT')}
                        margin="normal"
                    />
                    <TextField
                        id="pt"
                        label="PT (s)"
                        className={classes.textField}
                        value={this.state.PT}
                        onChange={this.handleChange('PT')}
                        margin="normal"
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple">Vascular Invasion</InputLabel>
                        <Select
                            value={this.state.VI}
                            onChange={this.handleChange('VI')}
                            inputProps={{
                                name: 'Vascular Invasion',
                                id: 'vi',
                            }}
                        >

                            <MenuItem value={1}>None</MenuItem>
                            <MenuItem value={2}>microvascular invasion</MenuItem>
                            <MenuItem value={3}>gross vascular invasion</MenuItem>
                        </Select>
                    </FormControl>




                    <Divider variant="fullWidth" className={classes.divider2} />


                    <TextField
                        id="standard-read-only-input"
                        label="RScore"
                        value={this.state.RScore}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="Median OS"
                        value={this.state.OS}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="Median RFS"
                        value={this.state.RFS}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-read-only-input"
                        label="ANN 2 Years Risk"
                        value={this.state.RScoreANN}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />



                </Paper>

            </main>
        );
    }
}

RScoreANN.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RScoreANN);