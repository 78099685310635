import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';


import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';



const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'left',
        flexGrow: 1,
        width: '100%',
        maxWidth: 550,
        padding: theme.spacing.unit * 3,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '100%',

    },

    titletext: {
        textAlign: 'center',
    },

    questions: {
        display: 'flex',
        flexWrap: 'wrap',
        // textAlign: 'center',
        // alignItems: 'center',
        justifyContent: 'left',
        flexDirection: 'column',
    },
    bottomtext: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    divider: {

        width: '100%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '100%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 200,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});





class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            age: '',


        };


    }





    handleChange = name => event => {




    };

    render() {
        const { classes } = this.props;



        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>About</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />

                    <div className={classes.questions}>

                        <Typography variant="subtitle1" gutterBottom>
                            1、BSA, SLV & GEMOX
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            计算体表面积、标准肝体积和GEMOX化疗方案中药物的剂量。
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            体表面积计算使用<a href="https://www.ncbi.nlm.nih.gov/pubmed/2520314" target="_blank" rel="noopener noreferrer">Du Bois算法</a>，即 BSA=0.007184*W^0.425*H^0.725。
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            成人标准肝体积的计算使用<a href="http://www.ncbi.nlm.nih.gov/pubmed/7737637" target="_blank" rel="noopener noreferrer">Urata K算法</a>，即 SLV=706.2*BSA+2.4。
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            我们使用的GEMOX是3周方案，每个周期分两次给药：
                        </Typography>
                        <Typography variant="body1" gutterBottom='false'>
                            D1：
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            5%GS 500ml + 奥沙利铂 85mg/m2 ivgtt，2 小时内滴完
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            NS 100ml + 吉西他滨 1000mg/m2 ivgtt，半小时内滴完
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            D8：
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            NS 100ml + 吉西他滨 1000mg/m2 ivgtt，半小时内滴完
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            一些缩略语：BSA，体表面积；FLV，残余肝体积；SLV，标准肝体积；FLV/SLV：残余肝占标准肝体积的百分比。
                        </Typography>

                    </div>


                    <Divider variant="fullWidth" className={classes.divider2} />

                    <div className={classes.questions}>
                        <Typography variant="subtitle1" gutterBottom>
                            2、Liver Cancer Risk
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            预测慢性乙肝患者/病毒携带者的肝癌发病率
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            参考自：<a href="http://www.whyes.org/?p=788" target="_blank" rel="noopener noreferrer">Yang et al. Lancet Oncol 2011</a>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            另有<a href="http://www.whyes.org/hydjan_apps/HCC_risk_est/HCC_risk_est_v1.03_win.exe" target="_blank" rel="noopener noreferrer"> Windows版</a>下载
                        </Typography>

                    </div>

                    <Divider variant="fullWidth" className={classes.divider2} />

                    <div className={classes.questions}>
                        <Typography variant="subtitle1" gutterBottom>
                            3、ALBI
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            计算公式：ALBI=0.66 * log10 （bilirubin）-0.085 * albumin
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Grade 1：最佳肝功能；Grade 3：最差肝功能。
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            参考自：<a href="http://ascopubs.org/doi/abs/10.1200/JCO.2014.57.9151" target="_blank" rel="noopener noreferrer">Johnson, et al. J Clin Oncol 2015</a>
                        </Typography>

                    </div>

                    <Divider variant="fullWidth" className={classes.divider2} />

                    <div className={classes.questions}>
                        <Typography variant="subtitle1" gutterBottom>
                            4、Shanghai Score
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            根据临床常见的14项指标，来评估肝癌患者接受根治性手术之后的复发和死亡风险
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            将14项指标输入之后，可以获得一个评分，根据评分的大小，分为复发低危、中危和高危的患者，并根据患者的风险情况，推荐合适的抗复发治疗。
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            参考自：<a href="http://www.cmj.org/text.asp?2017/130/22/2650/218019" target="_blank" rel="noopener noreferrer">Sun, et al. Chin Med J (Engl) 2017</a>
                        </Typography>

                    </div>

                    <Divider variant="fullWidth" className={classes.divider2} />

                    <div className={classes.bottomtext}>



                        <Typography variant="body1" gutterBottom>
                            By : <a href="https://www.whyes.org/" target="_blank" rel="noopener noreferrer">@Whyes</a> <a href="https://blog.hydjan.com" target="_blank" rel="noopener noreferrer">@Hydjan</a>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            2019.04
                        </Typography>

                    </div>





                </Paper>

            </main>
        );
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(About);