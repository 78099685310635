import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';



const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        maxWidth: 400,
        padding: theme.spacing.unit * 1,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '90%',

    },

    titletext: {
        textAlign: 'center',
    },

    divider: {

        width: '90%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '90%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 200,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});


let height;
let weight;
let flv;




class BsaSlvGemox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: '',
            weight: '',
            flv: '',
            bsa: '',
            slv: '',
            flv_slv: '',
            gem: '',
            ox: '',

        };
        height = 0;
        weight = 0;
        flv = 0;


    }




    handleChange = name => event => {

        var v = event.target.value
        if (v === '') {

            if (name === "height") {
                height = 0
            } else if (name === "weight") {
                weight = 0
            } else if (name === "flv") {
                flv = 0
            }

            this.setState({
                [name]: event.target.value,
                bsa: '',
                slv: '',
                flv_slv: '',
                gem: '',
                ox: '',

            });

        } else if (!isNaN(v)) {


            if (name === "height") {
                height = parseFloat(v)
            } else if (name === "weight") {
                weight = parseFloat(v)
            } else if (name === "flv") {
                flv = parseFloat(v)
            }

            if (height !== 0 && weight !== 0 && flv !== 0) {

                const bsa = 0.007184 * Math.pow(weight, 0.425) * Math.pow(height, 0.725);
                const slv = bsa * 706.2 + 2.4;
                const gem = bsa * 1000;
                const ox = bsa * 85;
                const flv_slv = flv / slv * 100;

                this.setState({
                    bsa: bsa.toFixed(2) + " m^2",
                    slv: slv.toFixed(2) + " mL",
                    flv_slv: flv_slv.toFixed(2) + " %",
                    gem: gem.toFixed(2) + " mg",
                    ox: ox.toFixed(2) + " mg",
                })

            }

            this.setState({ [name]: event.target.value });

        }

    };

    render() {
        const { classes } = this.props;

        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>BSA, SLV & GEMOX</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />

                    <TextField
                        id="ts"
                        label="Height (cm)"
                        className={classes.textField}
                        value={this.state.height}
                        onChange={this.handleChange('height')}
                        margin="normal"
                    />

                    <TextField
                        id="tn"
                        label="Weight (kg)"
                        className={classes.textField}
                        value={this.state.weight}
                        onChange={this.handleChange('weight')}
                        margin="normal"
                    />
                    <TextField
                        id="ggt"
                        label="FLV (mL)"
                        className={classes.textField}
                        value={this.state.flv}
                        onChange={this.handleChange('flv')}
                        margin="normal"
                    />


                    <Divider variant="fullWidth" className={classes.divider2} />

                    <TextField
                        id="standard-read-only-input"
                        label="BSA"
                        value={this.state.bsa}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="SLV"
                        value={this.state.slv}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="FLV/SLV"
                        value={this.state.flv_slv}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-read-only-input"
                        label="Gemcitabine"
                        value={this.state.gem}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-read-only-input"
                        label="Oxaliplation"
                        value={this.state.ox}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />


                </Paper>

            </main>
        );
    }
}

BsaSlvGemox.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BsaSlvGemox);