import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';



const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        maxWidth: 400,
        padding: theme.spacing.unit * 1,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '90%',

    },

    titletext: {
        textAlign: 'center',
    },

    divider: {

        width: '90%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '90%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 200,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});


let bilirubin;
let albumin;





class ALBI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bilirubin: '',
            albumin: '',

            albi_score: '',
            albi_grade: '',


        };
        bilirubin = 0;
        albumin = 0;


    }




    handleChange = name => event => {

        var v = event.target.value
        if (v === '') {

            if (name === "bilirubin") {
                bilirubin = 0
            } else if (name === "albumin") {
                albumin = 0
            } 

            this.setState({
                [name]: event.target.value,
                albi_score: '',
                albi_grade: '',


            });

        } else if (!isNaN(v)) {


            if (name === "bilirubin") {
                bilirubin = parseFloat(v)
            } else if (name === "albumin") {
                albumin = parseFloat(v)
            } 

            if (bilirubin !== 0 && albumin !== 0 ) {

                const albi_score = 0.66 * Math.log10(bilirubin) - 0.085 * albumin;

                var albi_grade = "Grade 0";
                if(albi_score <= -2.6){
                    albi_grade = "Grade 1 : 肝功能较好";
                }else if(albi_score <= -1.39){
                    albi_grade = "Grade 2 : 肝功能一般";
                }else{
                    albi_grade = "Grade 3 : 肝功能较差";
                }

                this.setState({
                    albi_score: albi_score.toFixed(2) ,
                    albi_grade: albi_grade,

                })

            }

            this.setState({ [name]: event.target.value });

        }

    };

    render() {
        const { classes } = this.props;

        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>ALBI</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />

                    <TextField
                        id="ts"
                        label="Bilirubin (μmol/L)"
                        className={classes.textField}
                        value={this.state.bilirubin}
                        onChange={this.handleChange('bilirubin')}
                        margin="normal"
                    />

                    <TextField
                        id="tn"
                        label="Albumin (g/L)"
                        className={classes.textField}
                        value={this.state.albumin}
                        onChange={this.handleChange('albumin')}
                        margin="normal"
                    />



                    <Divider variant="fullWidth" className={classes.divider2} />

                    <TextField
                        id="standard-read-only-input"
                        label="ALBI score"
                        value={this.state.albi_score}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="ALBI grade"
                        value={this.state.albi_grade}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                </Paper>

            </main>
        );
    }
}
ALBI.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ALBI);