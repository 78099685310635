import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';



const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        maxWidth: 550,
        padding: theme.spacing.unit * 1,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '90%',

    },

    titletext: {
        textAlign: 'center',
    },

    questions: {
        display: 'flex',
        flexWrap: 'wrap',
        // textAlign: 'center',
        // alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },

    divider: {

        width: '90%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '90%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 200,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});


let age;
let hbsag;
let hbeag;
let afp;
let tb;
let akp;
let ggt;
let ptt;
let cirrhosis;
let tumornumber;
let size;
let capsule;
let vi;
let differentiation;


class ShanghaiScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            age: '',
            hbsag: 1,
            hbeag: 1,
            afp: '',
            tb: '',
            akp: '',
            ggt: '',
            ptt: '',
            cirrhosis: 1,
            tumornumber: 1,
            size: '',
            capsule: 1,
            vi: 1,
            differentiation: 1,

            score: '',
            risk: '',

        };
        age = 0;
        hbsag = 1;
        hbeag = 1;
        afp = 0;
        tb = 0;
        akp = 0;
        ggt = 0;
        ptt = 0;
        cirrhosis = 1;
        tumornumber = 1;
        size = 0;
        capsule = 1;
        vi = 1;
        differentiation = 1;

    }





    handleChange = name => event => {

        var v = event.target.value
        if (v === '') {


            if (name === "age") {
                age = 0
            } else if (name === "afp") {
                afp = 0
            } else if (name === "tb") {
                afp = 0
            } else if (name === "akp") {
                akp = 0
            } else if (name === "ggt") {
                ggt = 0
            }
            else if (name === "ptt") {
                ptt = 0
            }
            else if (name === "size") {
                size = 0
            }

            this.setState({
                [name]: event.target.value,
                score: '',
                risk: '',



            });

        } else if (!isNaN(v)) {



            if (name === "age") {
                age = parseFloat(v);
            } else if (name === "afp") {
                afp = parseFloat(v);
            } else if (name === "tb") {
                tb = parseFloat(v);
            } else if (name === "akp") {
                akp = parseFloat(v);
            } else if (name === "ggt") {
                ggt = parseFloat(v);
            } else if (name === "ptt") {
                ptt = parseFloat(v);
            } else if (name === "size") {
                size = parseFloat(v);
            } else if (name === "hbsag") {
                hbsag = v;
            } else if (name === "hbeag") {
                hbeag = v;
            } else if (name === "cirrhosis") {
                cirrhosis = v;
            } else if (name === "tumornumber") {
                tumornumber = v;
            } else if (name === "capsule") {
                capsule = v;
            } else if (name === "vi") {
                vi = v;
            } else if (name === "differentiation") {
                differentiation = v;
            }

            if (age !== 0 && afp !== 0 && tb !== 0 && akp !== 0 && ggt !== 0 && ptt !== 0 && size !== 0) {

                var number012 = 0;
                switch (tumornumber) {
                    case 1:
                        number012 = 0;
                        break;
                    case 2:
                        number012 = 0.3740;
                        break;
                    case 3:
                        number012 = 0.3989 * 2;
                        break;
                    default:
                        break;
                }

                var vasinv = 0;

                switch (vi) {
                    case 1:
                        vasinv = 0;
                        break;
                    case 2:
                        vasinv = 0.4057;
                        break;
                    case 3:
                        vasinv = 0.8460 * 2;
                        break;
                    default:
                        break;
                }


                const shscore = 0.0082 * age + 0.1703 * (hbsag - 1) + 0.2954 * (hbeag - 1) + 0.1629 * (cirrhosis - 1) + 0.0400 * Math.log(afp) + 0.0023 * tb + 0.0876 * ptt + 0.1775 * Math.log(ggt) + 0.2981 * (differentiation - 1) + 0.0818 * size - 0.1743 * (capsule - 1) + 0.1669 * Math.log(akp) + vasinv + number012;

                var risk = "";
                if (shscore <= 4.129) {
                    risk = "Low Risk";
                } else if (shscore >= 4.922) {
                    risk = "High Risk";
                } else {
                    risk = "Medium Risk";
                }

                this.setState({
                    score: shscore.toFixed(2),
                    risk: risk,



                })

            }



            this.setState({ [name]: event.target.value });



        }




    };

    render() {
        const { classes } = this.props;



        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>Shanghai Score</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />

                    <div className={classes.questions}>
                        <TextField
                            id="ts"
                            label="Age"
                            className={classes.textField}
                            value={this.state.age}
                            onChange={this.handleChange('age')}
                            margin="normal"
                        />

                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">HBsAg</InputLabel>
                            <Select
                                value={this.state.hbsag}
                                onChange={this.handleChange('hbsag')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={1}>Negative</MenuItem>
                                <MenuItem value={2}>Positive</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">HBeAg</InputLabel>
                            <Select
                                value={this.state.hbeag}
                                onChange={this.handleChange('VI')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={1}>Negative</MenuItem>
                                <MenuItem value={2}>Positive</MenuItem>
                            </Select>
                        </FormControl>



                        <TextField
                            id="tn"
                            label="AFP (μg/L)"
                            className={classes.textField}
                            value={this.state.afp}
                            onChange={this.handleChange('afp')}
                            margin="normal"
                        />
                        <TextField
                            id="ggt"
                            label="TB (μmol/L)"
                            className={classes.textField}
                            value={this.state.tb}
                            onChange={this.handleChange('tb')}
                            margin="normal"
                        />
                        <TextField
                            id="pt"
                            label="AKP (μmol/L)"
                            className={classes.textField}
                            value={this.state.akp}
                            onChange={this.handleChange('akp')}
                            margin="normal"
                        />
                        <TextField
                            id="pt"
                            label="GGT (μmol/L)"
                            className={classes.textField}
                            value={this.state.ggt}
                            onChange={this.handleChange('ggt')}
                            margin="normal"
                        />
                        <TextField
                            id="pt"
                            label="PTT (s)"
                            className={classes.textField}
                            value={this.state.ptt}
                            onChange={this.handleChange('ptt')}
                            margin="normal"
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">Cirrhosis</InputLabel>
                            <Select
                                value={this.state.cirrhosis}
                                onChange={this.handleChange('cirrhosis')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={2}>Yes</MenuItem>
                                <MenuItem value={1}>No</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">Number of Tumor</InputLabel>
                            <Select
                                value={this.state.tumornumber}
                                onChange={this.handleChange('tumornumber')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>&gt;=3</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            id="pt"
                            label="Size (cm)"
                            className={classes.textField}
                            value={this.state.size}
                            onChange={this.handleChange('size')}
                            margin="normal"
                        />

                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">With Tumor Capsule</InputLabel>
                            <Select
                                value={this.state.capsule}
                                onChange={this.handleChange('capsule')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={2}>Yes</MenuItem>
                                <MenuItem value={1}>No</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">Vascular Invasion</InputLabel>
                            <Select
                                value={this.state.vi}
                                onChange={this.handleChange('vi')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={1}>None</MenuItem>
                                <MenuItem value={2}>microvascular invasion</MenuItem>
                                <MenuItem value={3}>gross vascular invasion</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">Differentiation</InputLabel>
                            <Select
                                value={this.state.differentiation}
                                onChange={this.handleChange('differentiation')}
                                inputProps={{
                                    name: 'Vascular Invasion',
                                    id: 'vi',
                                }}
                            >
                                <MenuItem value={1}>I-II (High)</MenuItem>
                                <MenuItem value={2}>III-IV (Low)</MenuItem>

                            </Select>
                        </FormControl>

                    </div>


                    <Divider variant="fullWidth" className={classes.divider2} />

                    <div className={classes.questions}>
                        <TextField
                            id="standard-read-only-input"
                            label="Shanghai Score"
                            value={this.state.score}
                            className={classes.textField}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            id="standard-read-only-input"
                            label="Risk"
                            value={this.state.risk}
                            className={classes.textField}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>




                </Paper>

            </main>
        );
    }
}

ShanghaiScore.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ShanghaiScore);