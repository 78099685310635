import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';



const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        maxWidth: 400,
        padding: theme.spacing.unit * 1,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '90%',

    },

    titletext: {
        textAlign: 'center',
    },

    divider: {

        width: '90%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '90%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 200,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});


let sex;
let age;
let alt;
let hbeag;
let hbv;



class LiverCancerRisk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sex: 1,
            age: 1,
            alt: 1,
            hbeag: 1,
            hbv: 1,


            nProb3: '',
            nProb5: '',
            nProb10: '',
        };
        sex = 1;
        age = 1;
        alt = 1;
        hbeag = 1;
        hbv = 1;

    }





    handleChange = name => event => {

        var v = event.target.value
        if (name === "sex") {
            sex = v;
        } else if (name === "age") {
            age = v;
        } else if (name === "alt") {
            alt = v;
        } else if (name === "hbeag") {
            hbeag = v;
        } else if (name === "hbv") {
            hbv = v
        }


        if (!isNaN(v)) {


            const nGrade = sex + age + alt + hbeag + hbv - 5;

            var nProb3, nProb5, nProb10;


            switch (nGrade) {
                case 0:
                    nProb3 = 0.0; nProb5 = 0.0; nProb10 = 0.0;
                    break;
                case 1:
                    nProb3 = 0.0; nProb5 = 0.0; nProb10 = 0.1;
                    break;
                case 2:
                    nProb3 = 0.0; nProb5 = 0.0; nProb10 = 0.1;
                    break;
                case 3:
                    nProb3 = 0.0; nProb5 = 0.1; nProb10 = 0.2;
                    break;
                case 4:
                    nProb3 = 0.0; nProb5 = 0.1; nProb10 = 0.3;
                    break;
                case 5:
                    nProb3 = 0.1; nProb5 = 0.2; nProb10 = 0.5;
                    break;
                case 6:
                    nProb3 = 0.1; nProb5 = 0.3; nProb10 = 0.7;
                    break;
                case 7:
                    nProb3 = 0.2; nProb5 = 0.5; nProb10 = 1.2;
                    break;
                case 8:
                    nProb3 = 0.3; nProb5 = 0.8; nProb10 = 2.0;
                    break;
                case 9:
                    nProb3 = 0.5; nProb5 = 1.2; nProb10 = 3.2;
                    break;
                case 10:
                    nProb3 = 0.9; nProb5 = 2.0; nProb10 = 5.2;
                    break;
                case 11:
                    nProb3 = 1.4; nProb5 = 3.3; nProb10 = 8.4;
                    break;
                case 12:
                    nProb3 = 2.3; nProb5 = 5.3; nProb10 = 13.4;
                    break;
                case 13:
                    nProb3 = 3.7; nProb5 = 8.5; nProb10 = 21.0;
                    break;
                case 14:
                    nProb3 = 6.0; nProb5 = 13.6; nProb10 = 32.0;
                    break;
                case 15:
                    nProb3 = 9.6; nProb5 = 21.3; nProb10 = 46.8;
                    break;
                case 16:
                    nProb3 = 15.2; nProb5 = 32.4; nProb10 = 64.4;
                    break;
                case 17:
                    nProb3 = 23.6; nProb5 = 47.4; nProb10 = 81.6;
                    break;

                default:
                    break;
            }





            this.setState({
                nProb3: nProb3.toFixed(2) + " %",
                nProb5: nProb5.toFixed(2) + " %",
                nProb10: nProb10.toFixed(2) + " %",


            })





            this.setState({ [name]: event.target.value });



        }




    };

    render() {
        const { classes } = this.props;



        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>Liver Cancer Risk</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />



                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple">SEX</InputLabel>
                        <Select
                            value={this.state.sex}
                            onChange={this.handleChange('sex')}
                            inputProps={{
                                name: 'SEX',
                                id: 'sex',
                            }}
                        >

                            <MenuItem value={1}>Female</MenuItem>
                            <MenuItem value={3}>Male</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple">AGE</InputLabel>
                        <Select
                            value={this.state.age}
                            onChange={this.handleChange('age')}
                            inputProps={{
                                name: 'AGE',
                                id: 'age',
                            }}
                        >
                            <MenuItem value={1}>&lt;35</MenuItem>
                            <MenuItem value={2}>35-39</MenuItem>
                            <MenuItem value={3}>40-44</MenuItem>
                            <MenuItem value={4}>45-49</MenuItem>
                            <MenuItem value={5}>50-54</MenuItem>
                            <MenuItem value={6}>55-59</MenuItem>
                            <MenuItem value={7}>&gt;60</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple">ALT(U/L)</InputLabel>
                        <Select
                            value={this.state.alt}
                            onChange={this.handleChange('alt')}
                            inputProps={{
                                name: 'ALT(U/L)',
                                id: 'alt',
                            }}
                        >

                            <MenuItem value={1}>&lt;15</MenuItem>
                            <MenuItem value={2}>15-44</MenuItem>
                            <MenuItem value={3}>&gt;45</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple">HBeAg</InputLabel>
                        <Select
                            value={this.state.hbeag}
                            onChange={this.handleChange('hbeag')}
                            inputProps={{
                                name: 'HBeAg',
                                id: 'hbeag',
                            }}
                        >

                            <MenuItem value={1}>Negative</MenuItem>
                            <MenuItem value={3}>Positive</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple">HBV DNA</InputLabel>
                        <Select
                            value={this.state.hbv}
                            onChange={this.handleChange('hbv')}
                            inputProps={{
                                name: 'HBV DNA',
                                id: 'hbv',
                            }}
                        >
                            <MenuItem value={1}>&lt;10^4</MenuItem>
                            <MenuItem value={4}>10^4-10^5</MenuItem>
                            <MenuItem value={6}>10^5-10^6</MenuItem>
                            <MenuItem value={5}>&gt;10^6</MenuItem>
                        </Select>
                    </FormControl>





                    <Divider variant="fullWidth" className={classes.divider2} />


                    <TextField
                        id="standard-read-only-input"
                        label="3 years"
                        value={this.state.nProb3}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="5 years"
                        value={this.state.nProb5}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="10 years"
                        value={this.state.nProb10}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />





                </Paper>

            </main>
        );
    }
}

LiverCancerRisk.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LiverCancerRisk);