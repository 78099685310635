import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';



const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        maxWidth: 400,
        padding: theme.spacing.unit * 1,


    },

    title: {
        // backgroundColor: '#7DB3D2',
        alignItems: 'center',
        width: '90%',

    },

    titletext: {
        textAlign: 'center',
    },

    divider: {

        width: '90%',
        marginBottom: theme.spacing.unit * 2,


    },

    divider2: {

        width: '90%',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    button: {
        marginTop: theme.spacing.unit * 1,

    },
    button2: {
        marginTop: theme.spacing.unit * 4,

    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing.unit,
        width: 300,

        input: {
            text: {

                textAlign: 'center',
            }


        },



    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    toolbar: theme.mixins.toolbar,

    content: {
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },


});



let afpbefore;
let afpafter;




class AfpResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            afpbefore: '',
            afpafter: '',
            afpresponse: '',
            result: '',


        };
        afpbefore = 0;
        afpafter = 0;



    }




    handleChange = name => event => {

        var v = event.target.value
        if (v === '') {

            if (name === "afpbefore") {
                afpbefore = 0
            } else if (name === "afpafter") {
                afpafter = 0
            } 

            this.setState({
                [name]: event.target.value,
                afpresponse: '',
                result: '',


            });

        } else if (!isNaN(v)) {


            if (name === "afpbefore") {
                afpbefore = parseFloat(v)
            } else if (name === "afpafter") {
                afpafter = parseFloat(v)
            } 

            if (afpbefore !== 0 && afpafter !== 0 ) {

                const afpresponse = Math.log(afpafter) / Math.log(afpbefore);

                var result = "AFP responder (favorable prognosis)";

                if (afpresponse > 0.8135) {
                    result = "AFP non-responder (unfavorable prognosis)";
                }

                this.setState({

                    afpresponse : afpresponse.toFixed(3),
                    result : result,
                })

            }

            this.setState({ [name]: event.target.value });

        }

    };

    render() {
        const { classes } = this.props;

        return (
            <main className={classes.root}>
                <div className={classes.toolbar} />

                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {/* <p>RScore for postoperative survival & recurrence</p> */}
                        <p className={classes.titletext}>AFP Response</p>
                    </div>

                    <Divider variant="fullWidth" className={classes.divider} />

                    <p className={classes.titletext}>serum AFP level (ng/mL)</p>

                    <TextField
                        id="ts"
                        label="Before surgery"
                        className={classes.textField}
                        value={this.state.afpbefore}
                        onChange={this.handleChange('afpbefore')}
                        margin="normal"
                    />

                    <TextField
                        id="tn"
                        label="6-8 days after surgery"
                        className={classes.textField}
                        value={this.state.afpafter}
                        onChange={this.handleChange('afpafter')}
                        margin="normal"
                    />


                    <Divider variant="fullWidth" className={classes.divider2} />

                    <TextField
                        id="standard-read-only-input"
                        label="AFP response"
                        value={this.state.afpresponse}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label="Result"
                        value={this.state.result}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />






                </Paper>

            </main>
        );
    }
}

AfpResponse.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AfpResponse);