import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import DnsIcon from '@material-ui/icons/Dns';
import GavelIcon from '@material-ui/icons/Gavel';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import DonutLarge from '@material-ui/icons/DonutLarge'

import InfoIcon from '@material-ui/icons/Info';


import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

class MyDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: this.props.mobileOpen,
        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            mobileOpen: nextProps.mobileOpen,
        });
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
        this.props.onMobileOpenChange();
    };

    handleOnClickButon (url)  {
        this.props.history.push(url);
        this.setState({ mobileOpen: !this.state.mobileOpen });
        this.props.onMobileOpenChange();
    };


    render() {
        const { classes, theme } = this.props;

        const drawer = (
            <div>
                <div className={classes.toolbar} />
                <Divider />
                <List>
                    <ListItem button key='home' onClick={() => this.handleOnClickButon('')}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary='Home' />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button key='bsa' onClick={() => this.handleOnClickButon('BsaSlvGemox')}>

                        <ListItemIcon><DescriptionIcon /></ListItemIcon>
                        <ListItemText primary='BSA,SLV&GEMOX' />

                    </ListItem>
                    <ListItem button key='livercancerrisk' onClick={() => this.handleOnClickButon('LiverCancerRisk')}>
                        <ListItemIcon><DnsIcon /></ListItemIcon>
                        <ListItemText primary='Liver Cancer Risk' />
                    </ListItem>
                    <ListItem button key='albi' onClick={() => this.handleOnClickButon('ALBI')}>
                        <ListItemIcon><GavelIcon /></ListItemIcon>
                        <ListItemText primary='ALBI' />
                    </ListItem>
                    <ListItem button key='shscore' onClick={() => this.handleOnClickButon('ShanghaiScore')}>
                        <ListItemIcon><AddToQueueIcon /></ListItemIcon>
                        <ListItemText primary='Shanghai Score' />
                    </ListItem>
                    <ListItem button key='RScore' onClick={() => this.handleOnClickButon('RScore')}>
                        <ListItemIcon><LocalHospitalIcon /></ListItemIcon>
                        <ListItemText primary='RScore' />
                    </ListItem>
                    <ListItem button key='AfpResponse' onClick={() => this.handleOnClickButon('AfpResponse')}>
                        <ListItemIcon><DonutLarge /></ListItemIcon>
                        <ListItemText primary='AFP Response' />
                    </ListItem>
                    {/* <ListItem button key='RScoreann' onClick={()=>this.handleOnClickButon("RScoreANN")}>
                        <ListItemIcon><LocalHospitalIcon /></ListItemIcon>
                        <ListItemText primary='RScore ANN' />
                    </ListItem> */}


                </List>

                <Divider />
                <List>
                    <ListItem button key='about' onClick={() => this.handleOnClickButon('About')}> 
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary='About' />
                    </ListItem>
                </List>

            </div>
        );

        return (



            <nav className={classes.drawer}>
                <CssBaseline />
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={this.props.container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>


        );
    }
}

MyDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(MyDrawer));