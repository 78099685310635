import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';

import { withStyles } from '@material-ui/core/styles';

import { Switch, Route } from 'react-router-dom'



import './App.css';
import MyBar from './MyBar.js';
import MyDrawer from './MyDrawer.js';

import RScore from './RScore.js';
import RScoreANN from './RScoreANN.js';
import BsaSlvGemox from './BsaSlvGemox.js';
import ALBI from './ALBI.js';
import LiverCancerRisk from './LiverCancerRisk.js';
import ShanghaiScore from './ShanghaiScore.js';
import About from './About.js';
import AfpResponse from './AfpResponse.js';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };

  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });

  };

  handleDrawerToggleFalse = () => {
    this.setState({ mobileOpen: false });

  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <MyBar  mobileOpen = {this.state.mobileOpen} onMobileOpenChange={this.handleDrawerToggle}></MyBar>
        <MyDrawer  mobileOpen = {this.state.mobileOpen} onMobileOpenChange={this.handleDrawerToggleFalse}></MyDrawer>

        <Switch>
          <Route exact path='/' component={BsaSlvGemox} />
          <Route path='/BsaSlvGemox' component={BsaSlvGemox} />
          <Route path='/ALBI' component={ALBI} />
          <Route path='/ShanghaiScore' component={ShanghaiScore} />
          <Route path='/LiverCancerRisk' component={LiverCancerRisk} />
          <Route path='/RScore' component={RScore} />
          <Route path='/AfpResponse' component={AfpResponse} />
          <Route path='/RScoreANN' component={RScoreANN} />
          <Route path='/About' component={About} />
        </Switch>



      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);
